/* Slim Slightly-transparent Button */
.EventItem-slim-button {
  /* Override Padding */
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;

  /* Standardize the Width */
  min-width: 2.125rem;
}

/* Make the button bigger for md+ screens */
@media ( min-width: 47.9375rem ) { /* Medium and Larger */
  .EventItem-slim-button {
    /* Sizing */
    font-size: 1.25rem !important;

    /* Standardize the Width */
    min-width: 2.375rem;
  }

  .EventItem-slim-button .btn-secondary {
    /* Medium Padding */
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
}

/* Large Screen Text Fader (instead of ellipsis) */
.EventItem-text-fader-outer {
  /* Take up no space (so the text flows under it) */
  width: 0;
  height: 0;

  /* Pass on pointer events (so title is still clickable through fade) */
  pointer-events: none;
}
.EventItem-text-fader-inner {
  /* Specify the size of the fader */
  /* (just an arbitrary size that fades out over a few characters) */
  width: 5rem;
  height: 2.5rem;

  /* Move outside the parent so it covers the text */
  transform: translate(-5rem, -1.25rem);
}
.EventItem-text-fader-inner-dark {
  /* Gradient that fades to the background color of alert-dark */
  background-image: linear-gradient(
    to right,
  rgba(207, 212, 217, 0),
  rgba(207, 212, 217, 1) 90% /* Fade early to provide space before buttons */
  );
}
.EventItem-text-fader-inner-warning {
  /* Gradient that fades to the background color of alert-warning */
  background-image: linear-gradient(
    to right,
  rgba(255, 193, 7, 0),
  rgba(255, 193, 7, 1) 90% /* Fade early to provide space before buttons */
  );
}

/* Small Screen Text Fader (instead of ellipsis for small screens) */
.EventItem-text-fader-outer-small {
  /* Sit on the top right of the page */
  position: absolute;
  top: 0;
  right: 0;

  /* Take up no space (so the text flows under it) */
  width: 0;
  height: 0;

  /* Pass on pointer events (so title is still clickable through fade) */
  pointer-events: none;
}
.EventItem-text-fader-inner-small {
  /* Specify the size of the fader */
  /* (just an arbitrary size that fades out over a few characters) */
  width: 5rem;
  height: 2.5rem;

  /* Move to left so it stays inside the parent */
  transform: translate(-5rem, 0);
}
.EventItem-text-fader-inner-small-dark {
  /* Gradient that fades to the background color of alert-dark */
  background-image: linear-gradient(
    to right,
  rgba(214, 216, 217, 0),
  rgba(214, 216, 217, 1)
  );
}
.EventItem-text-fader-inner-small-warning {
  /* Gradient that fades to the background color of alert-warning */
  background-image: linear-gradient(
    to right,
  rgba(255, 193, 7, 0),
  rgba(255, 193, 7, 1)
  );
}

/* Join Badges */
$join-badge-height: 0.9rem;
$join-badge-gap: 0.1rem;

.EventItem-join-badges {
  pointer-events: none;
  font-size: $join-badge-height * 0.85;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $join-badge-gap;

  margin-left: 0.3rem;

  height: 0;
  transform: translateY(calc(-1 * ($join-badge-height / 2) + $join-badge-gap));
  overflow: visible;
}
/* Vertical spacing if two badges */
.EventItem-join-badges-hybrid {
  transform: translateY(calc(-1 * $join-badge-height + ($join-badge-gap / 2)));
}
/* Uniform sizing and spacing */
.EventItem-join-badge {
  width: 100%;
  min-width: $join-badge-height * 3.73;
  height: $join-badge-height;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

/* Responsive Button/Title Layout for Small Screens */
@media (max-width: 47.9375rem) { /* Medium and Smaller */
  /* Stack Items Vertically */
  .EventItem-container {
    flex-direction: column;
  }

  /* Move Title Up for Readability */
  .EventItem-title-container {
    transform: translate(0, -0.1875rem);
  }

  /* Center the Buttons */
  .EventItem-button-container {
    text-align: center;
  }

  /* On small screens center align event title */
  .EventItem-h3 {
    text-align: center;
    width: 100%;
  }

  /* Slightly smaller badges */
  $join-badge-height: 0.7rem;
  $join-badge-gap: 0.1rem;

  .EventItem-join-badges {
    font-size: $join-badge-height * 0.85;
    gap: $join-badge-gap;

    transform: translateY(calc(-1 * ($join-badge-height / 2) + $join-badge-gap));
  }

  /* Vertical spacing if two badges */
  .EventItem-join-badges-hybrid {
    transform: translateY(calc(-1 * $join-badge-height));
  }

  /* Uniform sizing and spacing */
  .EventItem-join-badge {
    width: 100%;
    min-width: $join-badge-height * 3.73;
    height: 0.9rem;
  }
}

/* Hide dropdown arrow */
.EventItem-dropdown .dropdown-toggle::after {
  display: none;
}

/* Min width dropdown icons */
.EventItem-dropdown-item-icon {
  display: inline-block;
  min-width: 1.5rem;
  margin-right: 0.2rem;
  text-align: center;
}

.dropdown-item:focus {
  background-color: #333 !important;
  color: white !important;
}
