/* Responsive drawer gutter */
.AttendanceDashboard-drawer {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

/* Completely Disappear with small screens */
@media (max-width: 25rem) {
  .AttendanceDashboard-drawer {
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    background-color: transparent !important;
  }
  .AttendanceDashboard-drawer-inner {
    margin: 0 !important;
    padding: 0 !important;
  }
}

/* Animate in the attendance info drawer */
.AttendanceDashboard-drawer-open {
  animation-name: AttendanceDashboard-open;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-duration: 1s;
}

/* Drawer open (slide down) animation */
@keyframes AttendanceDashboard-open {
  0% {
    top: -12.5rem;
    position: relative;
    /* Not completely invisible to account for screen reader bugs */
    opacity: 0.0001;
  }
  50% {
    top: -12.5rem;
    position: relative;
    opacity: 0.0001;
  }
  100% {
    top: 0px;
    position: relative;
    opacity: 1;
  }
}
