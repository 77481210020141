.LoungeWindow-container {
  position: absolute;

  font-size: 0.875rem;

  color: white;

  opacity: 0.8;

  width: 0.875rem;
  height: 0.75rem;
}
