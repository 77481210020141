.LoungeTable-container {
  position: absolute;

  border-radius: 50%;

  width: 2.8125rem;
  height: 2.8125rem;

  /* background-color: #ffc107; */
  background-color: #6ECDDC;
}

.LoungeTable-paper {
  color: white;

  position: absolute;

  top: 0.5rem;
  left: 0.375rem;

  font-size: 1.0625rem;

  transform: rotate(190deg);
}
