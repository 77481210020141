/* Container for the Header */
.Header-container {
  /* Fixed at Top of Screen */
  position: fixed;
  top: 0;
  left: 0;

  /* On Top of Everything */
  z-index: 100000;

  /* Full Screen Width, Fixed Height */
  width: 100%;
  height: 3.3rem;

  /* Children in Middle */
  text-align: center;
}

/* Navbar */
.Header-navbar {
  min-height: 3.3rem;
}
.Header-navbar h2 {
  font-size: 2rem !important;
}

/* Left Button */
.Header-left-button {
  /* Override Font Weight */
  font-weight: bold !important;

  /* Position at Left of Header */
  position: fixed;
  top: 0.5rem;
  left: 0.3rem;
}

/* Right Button */
.Header-right-button {
  /* Override Font Weight */
  font-weight: bold !important;

  /* Position at Right of Header */
  position: fixed;
  top: 0.5rem;
  right: 0.3rem;
}

/* Striped Background to Match Admin Panel (when header is in admin view) */
.Header-striped-admin-background {
  background: repeating-linear-gradient(
    45deg,
    rgba(240, 173, 78, 1),
    rgba(240, 173, 78, 1) 14px,
    rgba(240, 173, 78, 0.95) 14px,
    rgba(240, 173, 78, 0.95) 28px
  );
}

/* Switch Text Color to Black when Background is Admin */
.Header-striped-admin-background h2 {
  color: black;
}

/* Icon Container */
.Header-icon-container {
  /* Don't increase the height of the header */
  height: 0;

  /* Width of the icon */
  width: 4rem;

  /* Add space between icon and title */
  margin-right: 0.5rem;

  /* Let the size be adjusted */
  display: inline-block;
}

/* Icon */
.Header-icon {
  /* Hard code the size of the icon */
  height: 2.3rem;

  /* Move up a little (so the icon is in line with text) */
  transform: translate(0, -0.2rem);
}

/* Version Number */
.Header-version-number {
  /* Bottom left of page */
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2000000;

  /* Small, transparent text */
  opacity: 0.6;
  font-size: 0.7rem;

  /* Padding */
  padding-left: 0.1rem;

  /* Does not interfere with mouse */
  pointer-events: none;
}
