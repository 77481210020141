/* This css file vs inline bootstrap is to clarify the ellipses in flex fix
* Referenced approach https://css-tricks.com/flexbox-truncated-text/
* Additional references in Stackoverflow
*/

/* The max-width 100 prevents text going off screen and keep items left aligned */
.EventDetails-container {
  max-width: 100%;
}

/* Necessary row flex to keep items left aligned */
.EventDetails-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/* The min-width 0 is to enable ellipsis in flex */
.EventDetails-row-item, .EventDetails-row-item > div {
  min-width: 0;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}
