@import './shared/styles/shared.scss';

/* Content below header */
.App-content-no-header,
.App-content-below-header {
  text-align: center;
}

/* Content Below the Header (add space on top where the header is) */
.App-content-below-header {
  padding-top: 5rem;
  padding-bottom: 1rem;
}

/* Content Container with Max Width */
.App-content-container {
  /* Max Width (standard) */
  max-width: 50rem;

  /* Center */
  margin: auto;

  /* Add Space on Bottom so Bottom Looks Nice */
  padding-bottom: 0.5rem;
}
