.LoungeItem-container {
  position: relative;

  /* Floor crosshatch */
  background-color: #c8e5f0 !important;
  background: linear-gradient(45deg, #D8F1F4 12%, transparent 0, transparent 88%, #D8F1F4 0), linear-gradient(135deg, transparent 37%, #EBFCFF 0, #EBFCFF 63%, transparent 0), linear-gradient(45deg, transparent 37%, #D8F1F4 0, #D8F1F4 63%, transparent 0), #A3D9DE;
  background-size: 0.7rem 0.7rem;

  /* Walls of the room */
  border-top: 1.25rem inset #A3D9DE !important;
  border-left: 1.25rem inset #AFE9EF !important;
  border-bottom: 1.25rem inset #c2dbde !important;
  border-right: 1.25rem inset #D9EFF3 !important;

  /* For a smooth hover animation */
  transition: transform 0.2s;
}

/* Hover styling */
.LoungeItem-container-hoverable {
  cursor: pointer;
}

.LoungeItem-container-hoverable:hover {
  transform: scale(1.007);
}

.LoungeItem-title {
  white-space: nowrap;
  display: inline-block;
  text-align: center;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (min-width: 41.125rem) {
  .LoungeItem-title {
    max-width: 31.25rem;
  }
}

@media (max-width: 26rem) {
  .LoungeItem-hide-26rem {
    display: none;
  }
}

@media (max-width: 23rem) {
  .LoungeItem-hide-23rem {
    display: none;
  }
}

/* Centered, fixed width, no overflow */
.LoungeItem-smiley-container {
  display: inline-block;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
  max-width: 100%;
  overflow: hidden;
  margin: auto;
  white-space: nowrap;
  text-overflow: ellipses;
}

/* Locked Notice */
.LoungeItem-locked-notice {
  display: inline-block;

  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;

  font-size: 1.125rem;
}

/* Join Button Contents */
.LoungeItem-join-button-contents {
  pointer-events: none;
}

/* Join Badges */
$join-badge-height: 0.9rem;
$join-badge-gap: 0.1rem;

.LoungeItem-join-badges {
  font-size: $join-badge-height * 0.85;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $join-badge-gap;

  margin-left: 0.3rem;

  height: 0;
  transform: translateY(-($join-badge-height / 2) + $join-badge-gap);
  overflow: visible;
}

/* Vertical spacing if two badges */
.LoungeItem-join-badges-hybrid {
  transform: translateY(-$join-badge-height + ($join-badge-gap / 2));
}

/* Uniform sizing and spacing */
.LoungeItem-join-badge {
  width: 100%;
  min-width: $join-badge-height * 3.73;
  height: $join-badge-height;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}