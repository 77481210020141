/* Shrink the Button for md and Smaller Screens */
@media ( max-width: 47.9375rem ) { /* Medium and Smaller */
  .Home-shrinking-button {
    /* Reduce the Padding */
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;

    /* Reduce the Font Size */
    font-size: 1.125rem !important;
  }
}
