/* Left-aligned for larger screens */
.RecordingPreview-title-text {
  text-align: left;
}

/* Responsive Layout */
@media (max-width: 48rem) {
  /* Switch to a column (stack items vertically) */
  .RecordingPreview-container {
    flex-direction: column;
  }

  /* Center the title */
  .RecordingPreview-title-text {
    text-align: center !important;
  }

  /* Add space between the title and the buttons */
  .RecordingPreview-buttons {
    margin-top: 0.3rem;
  }
}
