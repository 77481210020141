.AttendanceTable-container {
  max-width: 100%;
  max-height: 37.5rem;
  overflow: scroll;
  position: relative;
}

.AttendanceTable-table {
  position: relative;
  border-collapse: collapse;
}

.AttendanceTable-table td,
.AttendanceTable-table th {
  padding: 0.4em !important;
}

/* Header and footer need higher z-index than FadedTitleContainer in tbody */
.AttendanceTable-table > thead th,
.AttendanceTable-table > tfoot {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  z-index: 100001;
}

.AttendanceTable-table > thead th {
  top: 0;
}

.AttendanceTable-table > tfoot {
  bottom: 0;
}

/* Sticky horizontal position for body header
 * and the background color prevents scroll overlap
 */
.AttendanceTable-table > tbody tr th {
  background-color: white;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
}
/* Add striping to rows */
.AttendanceTable-table > tbody tr:nth-child(even),
.AttendanceTable-table > tbody tr:nth-child(even) th {
  background-color: rgb(226, 227, 229);
}

/* Fixed horizontal position for first header box in header and footer */
.AttendanceTable-table > thead th:first-child,
.AttendanceTable-table > tfoot th:first-child {
  z-index: 100002;
  left: 0;
}

/* Special sticky horizontal position for first footer header */
.AttendanceTable-table > tfoot th:first-child  {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
}

/* Remove cell borders, not sticky. Added inner shadow as border, its sticky */
.AttendanceTable-table th,
.AttendanceTable-table td {
  box-shadow: inset -0.0625rem 0.0625rem white;
  border: 0 !important;
}

/* Position footer cells so they can have borders */
.AttendanceTable-table > tfoot th,
.AttendanceTable-table > tfoot td {
  position: relative;
}
