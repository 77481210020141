/* Choice Container */
.ConfigureJoinSubpanel-choice-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  justify-content: center;
}

/* Join Buttons */
.ConfigureJoinSubpanel-choice-button {
  /* Specify Button Height */
  min-height: 6.25rem;

  /* Larger Text */
  font-size: 1.25rem;

  /* Reduce Padding so Text Can Fit */
  padding: 0.3125rem !important;
}

/* Text that appears on hover */
.ConfigureJoinSubpanel-text-to-show-on-hover {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}
.ConfigureJoinSubpanel-show-text-on-hover:hover .ConfigureJoinSubpanel-text-to-show-on-hover {
  max-height: 4rem;
  opacity: 1;
}

/* Graphic to visually explain joining in person */
$graphic-size: 10rem;
.ConfigureJoinSubpanel-in-person-graphic-container {
  position: relative;
  height: $graphic-size;
  margin-bottom: $graphic-size * 0.1;
  /* Re-center because the math was hard */
  transform: translateX($graphic-size * 0.16);
}
.ConfigureJoinSubpanel-in-person-graphic-phone {
  position: absolute;
  z-index: 5;
  left: calc(50% - ($graphic-size * 0.72));
  top: 0;
  transform: skewY(4deg) rotateY(10deg);
}
.ConfigureJoinSubpanel-in-person-graphic-phone-base-container {
  position: absolute;
  left: calc(0.01 * $graphic-size);
  top: calc(0.01 * $graphic-size);
  filter: drop-shadow(0 0 calc(0.03 * $graphic-size) white);
}
.ConfigureJoinSubpanel-in-person-graphic-phone-base {
  height: $graphic-size * 0.78;
}
.ConfigureJoinSubpanel-in-person-graphic-phone-blank-screen {
  position: absolute;
  left: $graphic-size * 0.075;
  top: $graphic-size * 0.04;
  border-radius: $graphic-size * 0.05;
  display: inline-block;
  background-color: white;
  width: $graphic-size * 0.45;
  height: $graphic-size * 0.72;
}
.ConfigureJoinSubpanel-in-person-graphic-phone-shutter-button {
  position: absolute;
  left: $graphic-size * 0.25;
  top: $graphic-size * 0.6;
  font-size: $graphic-size * 0.1;
  color: #666;
}
.ConfigureJoinSubpanel-in-person-graphic-phone-check-in-onscreen {
  position: absolute;
  left: 0;
  top: $graphic-size * 0.3;
  transform: scale(0.5) translate(calc(-0.15 * $graphic-size), calc(-0.8 * $graphic-size));
}
.ConfigureJoinSubpanel-in-person-graphic-phone-hand {
  position: absolute;
  z-index: 3;
  font-size: $graphic-size * 0.85;
  left: calc(50% - (0.81 * $graphic-size));
  top: calc(50% - (0.4 * $graphic-size));
  transform: rotateY(-20deg);
  color: #777;
  mask-image: linear-gradient(to bottom, #000, #000 50%, transparent 75%, transparent);
}
.ConfigureJoinSubpanel-in-person-graphic-phone-thumb {
  position: absolute;
  z-index: 10;
  display: inline-block;
  left: calc(50% - (0.74 * $graphic-size));
  top: calc(50% + (0.03 * $graphic-size));
  border-top-left-radius: $graphic-size * 0.1;
  border-top-right-radius: $graphic-size * 0.1;
  border-bottom-right-radius: $graphic-size * 0.1;
  transform: skewY(-20deg);
  width: $graphic-size * 0.25;
  height: $graphic-size * 0.15;
  background-color: #777;
  
  font-size: $graphic-size * 0.3;
  color: #777;
}
.ConfigureJoinSubpanel-in-person-graphic-phone-check-in-behind {
  position: absolute;
  left: calc(50% - ($graphic-size * 0.4));
  top: 0;
}
.ConfigureJoinSubpanel-in-person-graphic-check-in {
  display: inline-block;
  position: relative;
  width: $graphic-size * 0.75;
  height: $graphic-size * 0.88;
  border-radius: $graphic-size * 0.1;
  padding-top: $graphic-size * 0.1;
  padding-bottom: $graphic-size * 0.1;
  padding-left: $graphic-size * 0.11;
  padding-right: $graphic-size * 0.11;
  color: white;
}
.ConfigureJoinSubpanel-in-person-graphic-check-in-text {
  font-size: $graphic-size * 0.15;
  line-height: $graphic-size * 0.1;
  text-align: center;
  margin-bottom: $graphic-size * 0.05;
}
.ConfigureJoinSubpanel-in-person-graphic-qr-code {
  position: absolute;
  top: $graphic-size * 0.21;
  width: 75%;
  height: 75%;
}