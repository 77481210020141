/* Container for Published Description */
.RecordingsPanel-publish-description-container {
  text-align: right;
  position: relative;
  z-index: 100;
}

/* Container holding the arrow */
.RecordingsPanel-publish-description-arrow-container {
  position: absolute;

  /* Position on right and take up no space */
  width: 0;
  height: 0;
  right: 0;
}

/* The Arrow */
.RecordingsPanel-publish-description-arrow {
  /* Rotate the square */
  transform: rotate(45deg);

  /* Remove border */
  border: 0 !important;

  /* Size as rectangle */
  display: inline-block;
  width: 2rem;
  height: 2rem;

  /* Position */
  position: absolute;
  right: 3rem;
  top: -2rem;

  /* Put behind the message */
  z-index: -10;
}
