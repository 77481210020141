/* Container for tutorials (video or text) */
.HelpSubpanel-tutorial-container {
  /* Center in parent */
  margin: auto;

  /* Centered Text */
  text-align: center;
}

/* Button to expand and play a video */
.HelpSubpanel-expand-button {
  display: block;

  border: 0 !important;
  background: transparent !important;
  padding: 0 !important;
  margin: 0 !important;

  width: 100% !important;
}

/* Responsive Video */
.HelpSubpanel-video-responsive{
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.HelpSubpanel-video-responsive-taller {
  padding-bottom: 62.45% !important;
}
.HelpSubpanel-video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* Border around video */
.HelpSubpanel-video-container {
  border: 0.1rem solid #1E1E1E;
}
