.LoungeDoor-container {
  position: absolute;
  background-color: #6ECDDC;
  width: 1.875rem;
  height: 1rem;

  top: -1.125rem;
}

.LoungeDoor-left {
  position: absolute;
  top: 0;
  left: -0.3125rem;

  width: 0.3125rem;
  height: 1rem;

  overflow: hidden;
}

.LoungeDoor-left:after {
  content: '';
  background: #6ECDDC;
  width: 0.3125rem;
  height: 1.25rem;
  position: absolute;
  transform: rotate(-15deg) translate(0.0625rem, -0.25rem);
}

.LoungeDoor-right {
  position: absolute;
  top: 0;
  right: -0.3125rem;

  width: 0.3125rem;
  height: 1rem;

  overflow: hidden;
}

.LoungeDoor-right:after {
  content: '';
  background: #6ECDDC;
  width: 0.3125rem;
  height: 1.25rem;
  position: absolute;
  transform: rotate(15deg) translate(-0.3125rem, -0.1875rem);
}

.LoungeDoor-knob {
  position: absolute;
  left: 0.0625rem;
  top: 0.375rem;

  border-radius: 50%;
  background-color: #555;
  height: 0.25rem;
  width: 0.25rem;
}

.LoungeDoor-mat {
  position: absolute;

  top: 1.25rem;
  left: -0.5625rem;

  border-radius: 0.1875rem;
  background-color: #17a2b8;
  height: 1.25rem;
  width: 3.125rem;

  font-size: 0.625rem;
  color: white;
  font-weight: bold;
  padding-top: 0.1875rem;
}
