.AdminPanel-body-container {
  max-width: 60rem;
  margin: auto;
}

.AdminPanel-striped-admin-background {
  /* Fill Entire Screen */
  min-width: 100vw;
  min-height: 110vh;

  /* Lighter background */
  background-color: #5C9771;
}
