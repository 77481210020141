.PreAssignmentItem-outer-container {
  margin-bottom: 0.5rem !important;
  padding: 0.5rem !important;
}

.PreAssignmentItem-outer-container .PreAssignmentItem-column {
  height: 2.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.PreAssignmentItem-name-container {
  text-align: left;
  font-weight: bold;
  font-size: 1.3rem;
}

.PreAssignmentItem-group-num-container {
  text-align: center;
  font-size: 1.3rem;
}

.PreAssignmentItem-remove-button-container {
  text-align: right;
}
