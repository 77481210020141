.LoungeChair-container {
  position: absolute;

  width: 1.5625rem;
  height: 2.1875rem;
}

.LoungeChair-left {
  position: absolute;
  z-index: 10;

  top: 0;
  left: 0;

  width: 100%;
  height: 30%;

  border-radius: 0.3125rem;

  background-color: #17a2b8;
}

.LoungeChair-right {
  position: absolute;
  z-index: 10;

  bottom: 0;
  left: 0;

  width: 100%;
  height: 30%;

  border-radius: 0.3125rem;

  background-color: #17a2b8;
}

.LoungeChair-back {
  position: absolute;
  z-index: 10;

  top: 0;
  left: 0;

  width: 35%;
  height: 100%;

  border-radius: 0.3125rem;

  background-color: #17a2b8;
}

.LoungeChair-seat {
  position: absolute;
  z-index: 5;

  top: 0;
  left: 0;

  width: 85%;
  height: 100%;

  border-radius: 0.3125rem;

  background-color: #53ABB8;
}

.LoungeChair-pillow {
  position: absolute;
  z-index: 15;

  top: 28%;
  left: 33%;

  width: 20%;
  height: 44%;

  border-radius: 0.1875rem;

  background-color: #6ECDDC;
}
