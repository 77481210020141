/* When not pinned, don't show the button style */
.CreateEditEventSubpanel-pin-button-transparent {
  background-color: transparent !important;
  border: 0 !important;
}

/* Pin icon gets darker on hover */
.CreateEditEventSubpanel-pin-button-icon.CreateEditEventSubpanel-unpinned:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

.CreateEditEventSubpanel-pin-button {
  /* Put "Pinned to Top" text into overflow */
  width: 1rem;
  /* Set height to remove vertical motion on click */
  height: 1em;
  /* Prevent wrapping of "Pinned to Top" text */
  white-space: nowrap;
}

.CreateEditEventSubpanel-pin-button-icon {
  /* Center and rotate the icon */
  transform: translate(0, .06rem) rotate(20deg);
}

.CreateEditEventSubpanel-pin-button-icon.CreateEditEventSubpanel-unpinned {
  /* Reduce movement of icon when clicked */
  margin-left: .06rem !important;
}
