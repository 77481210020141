$width-rem: 2rem; // Must be same as var in .tsx

.Smiley-container {
  position: relative;
  display: inline-block;
  width: $width-rem;
  height: $width-rem;
  margin-left: 0.076923077 * $width-rem;
  margin-right: 0.076923077 * $width-rem;

  border-radius: 50%;

  background-color: #ffc107;

  border: (0.065 * $width-rem) solid black;

  transition: transform 0.1s;
}

.Smiley-container:hover {
  filter: brightness(1.1);
}

.Smiley-eyes {
  position: absolute;
  left: 0.461538462 * $width-rem;

  animation-name: Smiley-eye-animation;
  animation-iteration-count: infinite;
}

@keyframes Smiley-eye-animation {
  0% {
    transform: translate(0, 0);
  }

  10% {
    transform: translate(0, 0);
  }
  12% {
    transform: translate(0.076923077 * $width-rem, -(0.038461538 * $width-rem));
  }
  20% {
    transform: translate(0.076923077 * $width-rem, -(0.038461538 * $width-rem));
  }
  22% {
    transform: translate(0, 0);
  }

  30% {
    transform: translate(0, 0);
  }
  32% {
    transform: translate(-(0.076923077 * $width-rem), -(0.038461538 * $width-rem));
  }
  40% {
    transform: translate(-(0.076923077 * $width-rem), -(0.038461538 * $width-rem));
  }
  42% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, 0);
  }
  82% {
    transform: translate(0, (0.153846154 * $width-rem));
  }
  87% {
    transform: translate(0, (0.153846154 * $width-rem));
  }
  89% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

.Smiley-left-eye,
.Smiley-right-eye {
  position: absolute;
  display: inline-block;
  top: (0.192307692 * $width-rem);

  width: (0.192307692 * $width-rem);
  height: (0.307692308 * $width-rem);

  background-color: black;

  border-radius: (0.096153846 * $width-rem);

  animation-name: Smiley-blink-animation;
  animation-iteration-count: infinite;
}

.Smiley-left-eye {
  left: -0.25 * $width-rem;
}
.Smiley-right-eye {
  left: 0.057692308 * $width-rem;
}

@keyframes Smiley-blink-animation {
  0% {
    transform: scaleY(1);
  }
  20% {
    transform: scaleY(1);
  }
  20.5% {
    transform: scaleY(0.2);
  }
  21% {
    transform: scaleY(1);
  }
  37% {
    transform: scaleY(1);
  }
  37.5% {
    transform: scaleY(0.2);
  }
  38% {
    transform: scaleY(1);
  }
  60% {
    transform: scaleY(1);
  }
  60.5% {
    transform: scaleY(0.2);
  }
  61% {
    transform: scaleY(1);
  }
  99% {
    transform: scaleY(1);
  }
  99.5% {
    transform: scaleY(0.2);
  }
  100% {
    transform: scaleY(1);
  }
}

/* Customize the Tooltips */
.Smiley-tooltip {
  pointer-events: none !important;
}
.Smiley-tooltip .tooltip-inner {
  /* Change the colors to our theme */
  background-color: black !important;
  color: #F6C344 !important;
  border: (0.038461538 * $width-rem) solid black !important;
}

.Smiley-tooltip-bottom .arrow::before {
  /* Change the color to our theme */
  border-bottom: (0.192307692 * $width-rem) solid black !important;
}
